import React from "react"
import css from "./stylesheets/Sticky2column.module.scss"
import BasicText from "./BasicText"

export default props => {
  return (
    <div id={props.id}  className={css.wrapper} style={props.style}>
      <BasicText>
        <div className={css.wrap}>
          <div className={css.left}>{props.children[0]}</div>
          <div className={css.right}>{props.children[1]}</div>
        </div>
      </BasicText>
    </div>
  )
}
