import React, { Component } from "react"
import { Link } from "gatsby"
import css from "./stylesheets/LocalNavigation.module.scss"
import UrlCalc from "../utils/UrlCalc"
import AnchorScroll from "../utils/AnchorScroll"
import throttle from "lodash.throttle"

/**
 *
 */
class LocalNavigation extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props)
    this.doClickLink = this.doClickLink.bind(this)
    this.handleScroll = this.handleScroll.bind(this)

    this.state = {
      current: -1,
    }

    this.hashList = []
    for (let i = 0; i < this.props.links.length; i += 1) {
      this.hashList.push(UrlCalc.getHash(this.props.links[i].link, false))
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll)
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.handleScroll)
    }
  }

  handleScroll = throttle(e => {
    if (typeof window !== "undefined") {
      let hit = -1
      const naviHeight = AnchorScroll.calcNaviHeight()
      for (let i = 0; i < this.hashList.length; i += 1) {
        let el = document.getElementById(this.hashList[i])
        const rect = el.getBoundingClientRect()
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop
        const offsetTop = rect.top + scrollTop - naviHeight - 2
        if (offsetTop < scrollTop) {
          hit = i
        } else {
          break
        }
      }
      this.setState(state => ({
        current: hit,
      }))
    }
  }, 50)

  /**
   *
   * @param e
   */
  doClickLink(e) {
    if (typeof window !== "undefined") {
      if (e) e.preventDefault()
      let targetPath = e.currentTarget.getAttribute(`href`)
      AnchorScroll.doScrollDown(UrlCalc.getHash(targetPath))
    }
  }

  /**
   *
   * @returns {*}
   */
  render() {
    let list = []

    for (let i = 0; i < this.props.links.length; i += 1) {
      let data = this.props.links[i]
      let classCurrent = ""
      if (this.state.current === i) {
        classCurrent = css.Current
      }

      let targetWidth = "none"
      if (typeof document !== "undefined") {
        var newSpan = document.createElement("span")
        var newContent = document.createTextNode(data.title)
        newSpan.appendChild(newContent)
        document.body.appendChild(newSpan)
        newSpan.style.display = `inline-block`
        targetWidth = newSpan.clientWidth
        document.body.removeChild(newSpan)
      }

      let linkTag = (
        <Link
          onClick={this.doClickLink}
          className={classCurrent}
          to={data.link}
        >
          {data.title}
        </Link>
      )

      list.push(
        <li key={`content_item_${i}`} style={{ minWidth: targetWidth }}>
          {linkTag}
        </li>
      )
    }

    return (
      <div id={`localNavi`} className={css.base}>
        <ul>{list}</ul>
      </div>
    )
  }
}

export default LocalNavigation
