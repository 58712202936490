import React, { Component } from "react"
import LocalNavigation from "../../components/LocalNavigation"
import Sticky2colmun from "../../components/Sticky2column"
import LayoutBasic from "../../components/LayoutBasic"
import BasicText from "../../components/BasicText"
import Seo from "../../components/Seo"
import css from "./stylesheets/what-is-kaigo.module.scss"
import CenterContentLayout from "../../components/CenterContentLayout"

class PageBasicInformation extends Component {
  constructor(props) {
    super(props)
    this.doClickLangButton = this.doClickLangButton.bind(this)
  }

  doClickLangButton() {
    let layoutBasic = this.ref.layoutBasicInstance
    if (typeof layoutBasic !== "undefined" && layoutBasic !== null) {
      layoutBasic.ref.globalNavigationInstance.openLanguageMenu()
    }
  }

  render() {
    this.ref = {}

    return (
      <div>
        <Seo title={`WHAT is KAIGO?`} lang={`en`} />

        <LayoutBasic
          hasLocalNavi={true}
          reference={this.ref}
          lang={`en`}
          style={{ marginBottom: `0` }}
        >
          <LocalNavigation
            links={[
              { title: `Introduction`, link: `/en/what-is-kaigo/#section-1` },
              { title: `About Kaigo`, link: `/en/what-is-kaigo/#section-2` },
              {
                title: `About Us`,
                link: `/en/what-is-kaigo/#section-3`,
              },
            ]}
          />
          <CenterContentLayout>
            <BasicText>
              <h1 className={css.pageTitle}>WHAT is KAIGO?</h1>
            </BasicText>

            <Sticky2colmun id={`section-1`}>
              {/* column left*/}
              <div>
                <h2>Introduction</h2>
              </div>
              {/* /column left*/}

              {/* column right*/}
              <div>
                <p>
                  Welcome to our website!
                  <br />
                  ‘KAIGO in JAPAN’ is a website for those who are planning to
                  work in the care work field in Japan. We are willing to help
                  you with your questions about nursing care, and life in Japan.
                  We also have reports from those who already started their
                  career in Japan.
                  <br />
                  The contents include the interviews of the workers, the basic
                  information about Japan and nursing care, and FAQs.
                  <br />
                  You can select your language from ‘Easy Japanese,’ ‘Japanese’
                  and ‘English.’
                  <br />
                  We hope this website will help you to get to know more about
                  nursing care in Japan.
                </p>
                <p>
                  Supervision of Easy Japanese｜
                  <a
                    href="https://slow-communication.jp"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Slow Communication
                  </a>
                </p>
                <p className={`commonButton`}>
                  <button onClick={this.doClickLangButton}>
                    <div>
                      <span>LANGUAGE</span>
                    </div>
                  </button>
                </p>
              </div>
              {/* /column right*/}
            </Sticky2colmun>

            <Sticky2colmun id={`section-2`}>
              {/* column left*/}
              <div>
                <h2>About Kaigo</h2>
              </div>
              {/* /column left*/}

              {/* column right*/}
              <div>
                <p>
                  Nursing care refers to supporting those who have difficulties
                  with activities of daily living according to their conditions.
                  It includes elderly people and people with physical
                  disabilities or mental disorders. It indicates taking care of
                  individuals with meals, bathing and excreting. By observing
                  every little thing in daily living, nursing care will support
                  everyday life.
                  <br />
                  In Japan, those who need to be supported by nursing care can
                  receive the service using a public system. Every single person
                  deserves ‘to live,’ even if they don’t have anyone close
                  taking care of them. In addition, it produces more
                  opportunities for the elderly and people with disabilities to
                  be a part of society.
                  <br />
                  Nursing care services also reassure both those with
                  difficulties and their families. They don’t need to give up on
                  their jobs and their time because of their families with
                  difficulties. Nursing care plays an important role in society.
                  <br />
                  However, Japan holds a problem with a shortage of care workers
                  due to a decline in population as well as a rapid increase in
                  the elder population.
                </p>

                <p>
                  Nursing care is a specialized occupation requiring
                  understanding towards human beings scientifically and
                  creatively.
                  <br />
                  These are the three important factors:
                </p>

                <h3 className={css.specialHeading}>
                  <i className={css.specialHeadingPrefix}>1.</i>
                  <div>
                    Maintaining the everyday life of the elderly and the
                    disabled.
                  </div>
                </h3>
                <p>
                  The foundation of nursing care is to maintain one’s life. Each
                  of the factors such as pure air, sunlight, cleanliness,
                  laundry, meals, dressing, excretion, and bedding plays an
                  important role in maintaining daily living. Observing every
                  little thing in daily living, nursing care seeks for the best
                  solution to adjust one’s life and practice it.
                </p>

                <h3 className={css.specialHeading}>
                  <i className={css.specialHeadingPrefix}>2.</i>
                  <div>
                    Supporting an individual to be independent,
                    <br className={`only-pc`} />
                    utilizing their abilities.
                  </div>
                </h3>
                <p>
                  Even if it takes longer, we let those who are capable of going
                  to the toilet, or washing the dishes, to do it by themselves.
                  We let them do things they are able to do but support them
                  with things they are unable to do. It is important to have the
                  observation skills to judge what each individual is and is not
                  capable of.
                </p>

                <h3 className={css.specialHeading}>
                  <i className={css.specialHeadingPrefix}>3.</i>
                  <div>
                    Supporting an individual as ‘one living in society.’
                  </div>
                </h3>
                <p>
                  Nursing care also includes maintaining good relationships with
                  families and neighbors. It is important to enjoy small things
                  like going shopping and having a conversation with neighbors.
                  <br />
                  Care workers sometimes discuss how elderly patients want to
                  spend their last days with their families. If they or their
                  families request, we will accompany them to visit cemeteries
                  or go out to pray.
                </p>

                <h3 className={css.Heading3h}>
                  Our Life in a Nursing Care Facility <small>(3:47)</small>
                </h3>

                <div className={`fixedRatioBox is-16x9`}>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/i8hNvS-DbTk"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Our Life in a Nursing Care Facility"
                  ></iframe>
                </div>
              </div>
              {/* /column right*/}
            </Sticky2colmun>

            <Sticky2colmun id={`section-3`}>
              {/* column left*/}
              <div>
                <h2>About Us</h2>
              </div>
              {/* /column left*/}

              {/* column right*/}
              <div>
                <h3 className={css.specialHeading}>
                  Creating a convivial society utilizing welfare as a tool.
                </h3>
                <p>
                  FUKUSHI FOR CONVIVIALITY aims in developing a variety of
                  social actions to achieve a convivial society in which anyone
                  like children, people with disabilities and the elderly can
                  happily live in their own way in their communities.
                </p>
                <dl>
                  <dt>Corporate Name:</dt>
                  <dd>
                    General Incorporated Association FUKUSHI FOR CONVIVIALITY
                  </dd>
                  <dt>
                    Representative:
                    <br />
                    <small>(Co-CEO)</small>
                  </dt>
                  <dd>
                    Takuya Baba (Managing Director at Social Welfare Corporation
                    AIKAWA SHUNJU-KAI)
                    <br />
                    Daisuke Iida (CEO at FUKUSHI-GAKUDAN Social Welfare
                    Corporation)
                  </dd>
                  <dt>Address:</dt>
                  <dd>
                    WBG Marive East 12F, 2-6-1 Nakase, Mihama-ku, Chiba City,
                    Chiba #261-7112 Japan
                  </dd>
                  <dt>Contact:</dt>
                  <dd>
                    <a href="mailto:contact@f4c.jp" className={`linkUnderline`}>
                      contact@f4c.jp
                    </a>
                  </dd>
                  <dt>Website:</dt>
                  <dd>
                    <a
                      href={`https://f4c.jp`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://f4c.jp
                    </a>
                  </dd>
                </dl>
              </div>
              {/* /column right*/}
            </Sticky2colmun>
          </CenterContentLayout>
        </LayoutBasic>
      </div>
    )
  }
}
export default PageBasicInformation
